import { combineReducers } from 'redux';
import {
    ADD_VIDEO_MINT_SET,
    DROPS_ADMIN_ADDRESS_SET,
    DROPS_COLLECT_MINT_REVENUE_SET,
    DROPS_COLLECTION_ID_SET,
    DROPS_COLLECTION_NAME_SET,
    DROPS_COLLECTION_SYMBOL_SET,
    DROPS_COLLECTION_THUMBNAIL_SET,
    DROPS_DESCRIPTION_SET,
    DROPS_DIALOG_SEARCH_VALUE_SET,
    DROPS_JSON_SCHEMA_SET,
    DROPS_MAX_MINTS_SET,
    DROPS_PRICE_PER_MINT_SET,
    DROPS_SCHEMA_SET,
    DROPS_SELECT_TOKEN_SET,
    DROPS_TOTAL_SUPPLY_SET,
    FETCH_MY_IV_VIDEO_MINTS_ERROR,
    FETCH_MY_IV_VIDEO_MINTS_IN_PROGRESS,
    FETCH_MY_IV_VIDEO_MINTS_SUCCESS,
    HIDE_DELETE_IV_VIDEO_MINT_DIALOG,
    HIDE_DELETE_IV_VIDEO_MINT_SUCCESS_SET,
    HIDE_DROP_PREVIEW_DIALOG,
    HIDE_DROPS_LIST_EDIT_DIALOG,
    HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG,
    HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_SET,
    HIDE_SUBSCRIBE_MORE_DETAILS_DIALOG,
    INTERACT_TO_MINT_SWITCH_SET,
    MINT_EDITION_DESCRIPTION_SET,
    MINT_EDITION_NAME_SET,
    MINT_EDITION_NFT_ID_SET,
    MINT_EDITION_PREVIEW_URL_SET,
    PUBLISH_VIDEO_MINT_SETUP_SET,
    QUERY_CONTRACTS_CHANNEL_AUTH_DETAILS_SET,
    QUERY_CONTRACTS_CHANNEL_COLLECTION_SET,
    QUERY_CONTRACTS_CHANNEL_CONFIG_SET,
    QUERY_CONTRACTS_CHANNEL_TOKEN_DETAILS_SET,
    REMOVE_MINTER_CONTRACT_ERROR,
    REMOVE_MINTER_CONTRACT_IN_PROGRESS,
    REMOVE_MINTER_CONTRACT_SUCCESS,
    SELECT_VIDEO_DIALOG_TAB_SET,
    SHOW_DELETE_IV_VIDEO_MINT_DIALOG,
    SHOW_DROP_PREVIEW_DIALOG,
    SHOW_DROPS_LIST_EDIT_DIALOG,
    SHOW_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG,
    SHOW_SUBSCRIBE_MORE_DETAILS_DIALOG,
    SUBSCRIBE_AMOUNT_VALUE_SET,
    SUBSCRIBE_EXTENSIBILITY_SET,
    SUBSCRIBE_NFT_MEDIA_SET,
    SUBSCRIBE_NFT_PREVIEW_SET,
    SUBSCRIBE_NSFW_SET,
    SUBSCRIBE_ROYALTY_SET,
    SUBSCRIBE_ROYALTY_SHARE_SET,
    SUBSCRIBE_SWITCH_SET,
    SUBSCRIBE_TOKEN_NAME_SET,
    SUBSCRIBE_TOKEN_VALUE_SET,
    SUBSCRIBE_TOTAL_NFT_S_SET,
    SUBSCRIBE_TRANSFERABILITY_SET,
    VIDEO_MINT_ENABLE_SET,
    VIDEO_MINT_FIELDS_CLEAR,
    VIDEO_MINT_SELECT_VIDEO_SET,
    VIDEO_SETUP_SELECT_VIDEOS_DIALOG_HIDE,
    VIDEO_SETUP_SELECT_VIDEOS_DIALOG_SHOW,
    VIDEO_SETUP_SUCCESS_DIALOG_HIDE,
    VIDEO_SETUP_SUCCESS_DIALOG_SHOW,
} from '../../constants/videoMints';
import { tokensList } from '../../utils/defaultOptions';
import { FETCH_CHANNEL_INFO_SUCCESS } from '../../constants/channels';
import { SCHEMA_FETCH_ERROR, SCHEMA_FETCH_IN_PROGRESS, SCHEMA_FETCH_SUCCESS } from '../../constants/createAssets';
import { generateID } from '../../utils/generateID';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TOTAL } from '../../config';

const videoMintEnable = (state = false, action) => {
    switch (action.type) {
    case VIDEO_MINT_ENABLE_SET:
        return action.value;
    default:
        return state;
    }
};

const videoSetupSelectVideosDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case VIDEO_SETUP_SELECT_VIDEOS_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case VIDEO_MINT_SELECT_VIDEO_SET:
        return {
            ...state,
            value: action.value,
        };
    case VIDEO_SETUP_SELECT_VIDEOS_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const videoSetupSuccessDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case VIDEO_SETUP_SUCCESS_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case VIDEO_SETUP_SUCCESS_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const dropsCollectionName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case DROPS_COLLECTION_NAME_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
        };
    case FETCH_CHANNEL_INFO_SUCCESS: {
        let name = action.value && action.value.name;
        if (name && name.split(' Channel')) {
            name = `${name.split(' Channel')[0]} Drops`;
        } else {
            name = action.value && action.value.name ? `${action.value.name} Drops` : null;
        }

        return {
            value: name,
        };
    }

    default:
        return state;
    }
};

const dropsCollectionThumbnail = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case DROPS_COLLECTION_THUMBNAIL_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
        };
    case FETCH_CHANNEL_INFO_SUCCESS:
        return {
            value: action.value && action.value.profile_picture ? action.value.profile_picture : null,
        };

    default:
        return state;
    }
};

const dropsCollectionSymbol = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case DROPS_COLLECTION_SYMBOL_SET:
        return {
            value: action.value,
        };
    case FETCH_CHANNEL_INFO_SUCCESS: {
        let name = action.value && (action.value.symbol || action.value.username || action.value.name);
        if (name && name.split(' Channel')) {
            name = `${name.split(' Channel')[0]}Drops`;
        } else {
            name = action.value && (action.value.symbol || action.value.username || action.value.name)
                ? (action.value.symbol || action.value.username || action.value.name) : null;
        }

        return {
            value: name,
        };
    }

    default:
        return state;
    }
};

const dropsCollectionID = (state = {
    value: 'onftdenom' + generateID(),
}, action) => {
    switch (action.type) {
    case DROPS_COLLECTION_ID_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: 'onftdenom' + generateID(),
        };

    default:
        return state;
    }
};

const dropsCollectMintRevenue = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case DROPS_COLLECT_MINT_REVENUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
        // case VIDEO_MINT_FIELDS_CLEAR:
        //     return {
        //         value: '',
        //         valid: false,
        //     };
        // case FETCH_CHANNEL_INFO_SUCCESS:
        //     return {
        //         value: action.value && action.value.name ? `${action.value.name} Drops` : null,
        //     };

    default:
        return state;
    }
};

const dropsAdminAddress = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case DROPS_ADMIN_ADDRESS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
        // case VIDEO_MINT_FIELDS_CLEAR:
        //     return {
        //         value: '',
        //         valid: true,
        //     };
        // case FETCH_CHANNEL_INFO_SUCCESS:
        //     return {
        //         value: action.value && action.value.name ? `${action.value.name} Drops` : null,
        //     };

    default:
        return state;
    }
};

const dropsDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case DROPS_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
            valid: true,
        };
    case FETCH_CHANNEL_INFO_SUCCESS:
        return {
            value: action.value && action.value.description ? `${action.value.description}` : null,
        };

    default:
        return state;
    }
};

const dropsJsonSchema = (state = {
    jsonSchema: '',
    jsonValid: true,
}, action) => {
    switch (action.type) {
    case DROPS_JSON_SCHEMA_SET:
        return {
            ...state,
            jsonSchema: action.value,
            jsonValid: action.valid,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            ...state,
            jsonSchema: '',
            jsonValid: true,
        };
        // case FETCH_CHANNEL_INFO_SUCCESS:
        //     return {
        //         value: action.value && action.value.name ? `${action.value.name} Drops` : null,
        //     };

    default:
        return state;
    }
};

const dropsSchemas = (state = {
    inProgress: false,
    list: [],
    value: null,
}, action) => {
    switch (action.type) {
    case SCHEMA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SCHEMA_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
        };
    case SCHEMA_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DROPS_SCHEMA_SET:
        return {
            ...state,
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            inProgress: false,
            list: [],
            value: null,
        };
    default:
        return state;
    }
};

const addVideoMint = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ADD_VIDEO_MINT_SET:
        return {
            ...state,
            value: action.value,
            open: action.open,
        };

    default:
        return state;
    }
};

const queryContractsChannelInfo = (state = {
    channelCollection: {},
    channelConfig: {},
    channelAuthDetails: {},
    channelTokenDetails: {},
}, action) => {
    switch (action.type) {
    case QUERY_CONTRACTS_CHANNEL_COLLECTION_SET:
        return {
            ...state,
            channelCollection: action.value,
        };
    case QUERY_CONTRACTS_CHANNEL_CONFIG_SET:
        return {
            ...state,
            channelConfig: action.value,
        };
    case QUERY_CONTRACTS_CHANNEL_AUTH_DETAILS_SET:
        return {
            ...state,
            channelAuthDetails: action.value,
        };
    case QUERY_CONTRACTS_CHANNEL_TOKEN_DETAILS_SET:
        return {
            ...state,
            channelTokenDetails: action.value,
        };

    default:
        return state;
    }
};

const dropsPriceMint = (state = {
    value: 0,
}, action) => {
    switch (action.type) {
    case DROPS_PRICE_PER_MINT_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: 0,
        };

    default:
        return state;
    }
};

const dropsMaxMints = (state = {
    value: 1,
}, action) => {
    switch (action.type) {
    case DROPS_MAX_MINTS_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: 1,
        };

    default:
        return state;
    }
};

const dropsSelectTokens = (state = {
    value: tokensList && tokensList[0],
    options: tokensList,
}, action) => {
    switch (action.type) {
    case DROPS_SELECT_TOKEN_SET:
        return {
            ...state,
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: tokensList && tokensList[0],
            options: tokensList,
        };
    default:
        return state;
    }
};

const dropsTotalSupply = (state = {
    value: 50,
}, action) => {
    switch (action.type) {
    case DROPS_TOTAL_SUPPLY_SET:
        return {
            ...state,
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: 50,
        };

    default:
        return state;
    }
};

const interactToMintSwitch = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case INTERACT_TO_MINT_SWITCH_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const subscribeToken = (state = {
    value: tokensList && tokensList[0],
    valid: true,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_TOKEN_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: tokensList && tokensList[0],
            valid: true,
        };

    default:
        return state;
    }
};

const subscribeAmount = (state = {
    value: '0',
    valid: true,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_AMOUNT_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '0',
            valid: true,
        };

    default:
        return state;
    }
};

const subscribeMoreDetailsDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case SHOW_SUBSCRIBE_MORE_DETAILS_DIALOG:
        return {
            value: action.value,
            open: true,
        };
    case HIDE_SUBSCRIBE_MORE_DETAILS_DIALOG:
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: {},
            open: false,
        };

    default:
        return state;
    }
};

const subscribeSwitch = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_SWITCH_SET:
        return {
            open: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            open: false,
        };

    default:
        return state;
    }
};

const subscribeRoyalty = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_ROYALTY_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: false,
        };
    default:
        return state;
    }
};

const subscribeRoyaltyShare = (state = {
    value: 0,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_ROYALTY_SHARE_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: 0,
        };
    default:
        return state;
    }
};

const subscribeTransferability = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_TRANSFERABILITY_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: true,
        };
    default:
        return state;
    }
};

const subscribeExtensibility = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_EXTENSIBILITY_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: true,
        };
    default:
        return state;
    }
};

const subscribeNSFW = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_NSFW_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: false,
        };
    default:
        return state;
    }
};

const subscribeTokenName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_TOKEN_NAME_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const subscribeNFTMedia = (state = {
    value: '',
    mediaType: null,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_NFT_MEDIA_SET:
        return {
            ...state,
            value: action.value,
            mediaType: action.mediaType,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
            mediaType: null,
        };
    default:
        return state;
    }
};

const subscribeNFTPreview = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_NFT_PREVIEW_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const subscribeTotalNFTs = (state = {
    value: '50',
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_TOTAL_NFT_S_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '50',
        };
    default:
        return state;
    }
};

// Open Edition Mint
const mintEditionName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_EDITION_NAME_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const mintEditionNftID = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_EDITION_NFT_ID_SET:
        return {
            value: action.value,
        };
    case VIDEO_MINT_FIELDS_CLEAR:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const mintEditionDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case MINT_EDITION_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case FETCH_CHANNEL_INFO_SUCCESS: {
        if (action.value && action.value.name) {
            return {
                ...state,
                value: `This is a collection created to be the home of all NFTs minted via the Video Mints feature of the ${action.value.name} community.`,
            };
        } else {
            return {
                ...state,
                value: action.value && action.value.description ? `${action.value.description}` : null,
            };
        }
    }
    // case VIDEO_MINT_FIELDS_CLEAR:
    //     return {
    //         value: '',
    //         valid: true,
    //     };

    default:
        return state;
    }
};

const mintEditionPreviewURL = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MINT_EDITION_PREVIEW_URL_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const removeMinterContract = (state = {
    inProgress: false,
    list: [],
    value: null,
}, action) => {
    switch (action.type) {
    case REMOVE_MINTER_CONTRACT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REMOVE_MINTER_CONTRACT_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
        };
    case REMOVE_MINTER_CONTRACT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const removeContractSuccessDialog = (state = {
    open: false,
    value: {},
    success: false,
}, action) => {
    switch (action.type) {
    case SHOW_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG:
        return {
            value: action.value,
            open: true,
        };
    case HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG:
        return {
            value: {},
            open: false,
        };
    case HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_SET:
        return {
            ...state,
            success: action.value,
        };

    default:
        return state;
    }
};

const dropsListEditDialog = (state = {
    value: {},
    open: false,
    isVOD: false,
}, action) => {
    switch (action.type) {
    case SHOW_DROPS_LIST_EDIT_DIALOG:
        return {
            value: action.value,
            isVOD: action.isVOD,
            open: true,
        };
    case HIDE_DROPS_LIST_EDIT_DIALOG:
        return {
            value: {},
            isVOD: false,
            open: false,
        };

    default:
        return state;
    }
};

const myIVVideoMints = (state = {
    inProgress: false,
    list: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
    search: '',
    publishStatuses: null,
}, action) => {
    switch (action.type) {
    case FETCH_MY_IV_VIDEO_MINTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_MY_IV_VIDEO_MINTS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                search: action.search,
                publishStatuses: action.publishStatuses,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                search: action.search,
            };
        }
    case FETCH_MY_IV_VIDEO_MINTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deleteIVVideoDialog = (state = {
    value: {},
    open: false,
    success: false,
}, action) => {
    switch (action.type) {
    case SHOW_DELETE_IV_VIDEO_MINT_DIALOG:
        return {
            value: action.value,
            open: true,
        };
    case HIDE_DELETE_IV_VIDEO_MINT_DIALOG:
        return {
            value: {},
            open: false,
        };
    case HIDE_DELETE_IV_VIDEO_MINT_SUCCESS_SET:
        return {
            ...state,
            success: true,
        };

    default:
        return state;
    }
};

const publishVideoMintSetup = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PUBLISH_VIDEO_MINT_SETUP_SET:
        return {
            value: action.value,
            open: action.open,
        };

    default:
        return state;
    }
};

const selectVideoDialogTab = (state = 'all', action) => {
    if (action.type === SELECT_VIDEO_DIALOG_TAB_SET) {
        return action.value;
    }

    return state;
};

const selectVideoDialogSearch = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case DROPS_DIALOG_SEARCH_VALUE_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const dropPreviewDialog = (state = {
    open: false,
    value: {},
    url: '',
    mediaType: '',
}, action) => {
    switch (action.type) {
    case SHOW_DROP_PREVIEW_DIALOG:
        return {
            open: true,
            value: action.value,
            url: action.url,
            mediaType: action.mediaType,
        };
    case HIDE_DROP_PREVIEW_DIALOG:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    videoMintEnable,
    videoSetupSelectVideosDialog,
    videoSetupSuccessDialog,
    dropsCollectionName,
    dropsCollectionSymbol,
    dropsPriceMint,
    dropsMaxMints,
    dropsSelectTokens,
    dropsTotalSupply,
    interactToMintSwitch,
    dropsCollectionThumbnail,

    dropsCollectionID,
    dropsCollectMintRevenue,
    dropsAdminAddress,
    dropsDescription,
    dropsJsonSchema,
    dropsSchemas,

    addVideoMint,
    queryContractsChannelInfo,

    subscribeToken,
    subscribeAmount,
    subscribeMoreDetailsDialog,
    subscribeSwitch,

    subscribeRoyalty,
    subscribeRoyaltyShare,
    subscribeTransferability,
    subscribeExtensibility,
    subscribeNSFW,

    subscribeTokenName,
    subscribeNFTMedia,
    subscribeNFTPreview,
    subscribeTotalNFTs,

    mintEditionName,
    mintEditionNftID,
    mintEditionDescription,
    mintEditionPreviewURL,

    removeMinterContract,
    removeContractSuccessDialog,

    dropsListEditDialog,
    myIVVideoMints,
    deleteIVVideoDialog,

    publishVideoMintSetup,
    selectVideoDialogTab,
    selectVideoDialogSearch,

    dropPreviewDialog,
});
