import { combineReducers } from 'redux';
import {
    CREATOR_VALUE_SET,
    DENOM_ID_VALUE_SET,
    HIDE_PARTICIPATION_NFT_PREVIEW_DIALOG,
    INITIAL_NFT_INDEX_VALUE_SET,
    MINTED_UNTIL_TIME_SET,
    NFT_SELECT_COLLECTIONS_SET,
    PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_ERROR,
    PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_IN_PROGRESS,
    PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_SUCCESS,
    PARTICIPATION_NFT_FETCH_ERROR,
    PARTICIPATION_NFT_FETCH_IN_PROGRESS,
    PARTICIPATION_NFT_FETCH_SUCCESS,
    PARTICIPATION_NFT_SWITCH_SET,
    REQUIRED_SCORE_PERCENTAGE_SET,
    ROYALITY_SHARE_VALUE_SET,
    SHOW_PARTICIPATION_NFT_PREVIEW_DIALOG,
    TOTAL_UNITS_SET,
    VIDEO_DROPS_CANCEL_FETCH_COLLECTIONS,
    VIDEO_DROPS_COLLECTIONS_FETCH_ERROR,
    VIDEO_DROPS_COLLECTIONS_FETCH_IN_PROGRESS,
    VIDEO_DROPS_COLLECTIONS_FETCH_SUCCESS,
    VIDEO_DROPS_COUNTER_SWITCH_SET,
    VIDEO_DROPS_DESCRIPTION_SET,
    VIDEO_DROPS_EXTENSIBILITY_SWITCH_SET,
    VIDEO_DROPS_FIELDS_CLEAR_SET,
    VIDEO_DROPS_MEDIA_URL_SET,
    VIDEO_DROPS_NAME_SET,
    VIDEO_DROPS_NSFW_SWITCH_SET,
    VIDEO_DROPS_PREVIEW_URL_SET,
    VIDEO_DROPS_ROYALTY_SWITCH_SET,
    VIDEO_DROPS_TRANSFERABILITY_SWITCH_SET,
    VIDEO_DROPS_TWEET_TEXT_SET,
} from '../../constants/interactiveVideos/videoDrops';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP } from '../../config';
import { INTERACTIVE_VIDEO_FETCH_SUCCESS } from '../../constants/interactiveVideos/new';
import { parseISO } from 'date-fns';

const participationNftEnable = (state = false, action) => {
    switch (action.type) {
    case PARTICIPATION_NFT_SWITCH_SET:
        return action.value;
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return action.result && action.result.participant_nft ? true : state;
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return false;
    default:
        return state;
    }
};

const totalUnits = (state = {
    value: null,
}, action) => {
    switch (action.type) {
    case TOTAL_UNITS_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.total_units) {
            return {
                value: action.result.participant_nft.total_units,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: null,
        };
    default:
        return state;
    }
};

const initialNftIndex = (state = {
    value: 1,
}, action) => {
    switch (action.type) {
    case INITIAL_NFT_INDEX_VALUE_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.initial_nft_index) {
            return {
                value: action.result.participant_nft.initial_nft_index,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: 1,
        };
    default:
        return state;
    }
};

const counterSwitch = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_COUNTER_SWITCH_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.is_counter_required) {
            return {
                value: action.result.participant_nft.is_counter_required,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: false,
        };
    default:
        return state;
    }
};

const denomId = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case DENOM_ID_VALUE_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.denom_id) {
            return {
                value: {
                    id: action.result.participant_nft.denom_id,
                },
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const creatorValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CREATOR_VALUE_SET:
        return {
            value: action.value,
        };
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const videoDropsName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_NAME_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.name) {
            return {
                value: action.result.participant_nft.name,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const videoDropsDescription = (state = {
    value: '',
    valid: false,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.description) {
            return {
                value: action.result.participant_nft.description,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const royalitySwitch = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_ROYALTY_SWITCH_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.royalty_share &&
            action.result.participant_nft.royalty_share > 0) {
            return { value: true };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: false,
        };
    default:
        return state;
    }
};

const royalityShare = (state = {
    value: 0,
}, action) => {
    switch (action.type) {
    case ROYALITY_SHARE_VALUE_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.royalty_share &&
            action.result.participant_nft.royalty_share > 0) {
            return { value: action.result.participant_nft.royalty_share * 100 };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: 0,
        };
    default:
        return state;
    }
};

const transferabilitySwitch = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_TRANSFERABILITY_SWITCH_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && (action.result.participant_nft.transferable || !action.result.participant_nft.transferable)) {
            return {
                value: action.result.participant_nft.transferable,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: true,
        };
    default:
        return state;
    }
};

const extensibilitySwitch = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_EXTENSIBILITY_SWITCH_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && (action.result.participant_nft.extensible || !action.result.participant_nft.extensible)) {
            return {
                value: action.result.participant_nft.extensible,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: false,
        };
    default:
        return state;
    }
};

const nfswSwitch = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_NSFW_SWITCH_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && (action.result.participant_nft.nsfw || !action.result.participant_nft.nsfw)) {
            return {
                value: action.result.participant_nft.nsfw,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: false,
        };
    default:
        return state;
    }
};

const videoDropsTweetText = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_TWEET_TEXT_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.tweet_text) {
            return {
                value: action.result.participant_nft.tweet_text,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const videoDropsPreviewURL = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_PREVIEW_URL_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.preview_uri) {
            return {
                value: action.result.participant_nft.preview_uri,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const videoDropsMediaURL = (state = {
    value: '',
    type: null,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_MEDIA_URL_SET:
        return {
            value: action.value,
            type: action.mediaType,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.media_uri) {
            return {
                value: action.result.participant_nft.media_uri,
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: '',
            type: null,
        };
    default:
        return state;
    }
};

const collection = (state = {
    value: null,
    options: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
    cancel: null,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_COLLECTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case VIDEO_DROPS_COLLECTIONS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                options: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
                cancel: null,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                options: [...state.options, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
                cancel: null,
            };
        }
    case VIDEO_DROPS_COLLECTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            cancel: null,
        };
    case VIDEO_DROPS_CANCEL_FETCH_COLLECTIONS:
        return {
            ...state,
            cancel: action.cancel,
        };
    default:
        return state;
    }
};

const nftSelectCollections = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case NFT_SELECT_COLLECTIONS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: null,
            valid: true,
        };
    default:
        return state;
    }
};

const participationNftConfig = (state = {
    inProgress: false,
    value: {},
    result: {},
}, action) => {
    switch (action.type) {
    case PARTICIPATION_NFT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PARTICIPATION_NFT_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
            result: action.value,
        };
    case PARTICIPATION_NFT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const participantNFTAuthzAccountAddress = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const requiredPercentage = (state = {
    value: 0,
}, action) => {
    switch (action.type) {
    case REQUIRED_SCORE_PERCENTAGE_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.required_score_percentage &&
                action.result.participant_nft.required_score_percentage > 0) {
            return { value: action.result.participant_nft.required_score_percentage * 100 };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: 0,
        };
    default:
        return state;
    }
};

const mintedUntilValue = (state = {
    value: null,
}, action) => {
    switch (action.type) {
    case MINTED_UNTIL_TIME_SET:
        return {
            value: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS: {
        if (action.result && action.result.participant_nft && action.result.participant_nft.minted_until) {
            return {
                value: parseISO(action.result.participant_nft.minted_until),
            };
        }

        return state;
    }
    case VIDEO_DROPS_FIELDS_CLEAR_SET:
        return {
            value: null,
        };

    default:
        return state;
    }
};

const participantNftPreviewDialog = (state = {
    open: false,
    value: {},
    url: '',
    mediaType: '',
}, action) => {
    switch (action.type) {
    case SHOW_PARTICIPATION_NFT_PREVIEW_DIALOG:
        return {
            open: true,
            value: action.value,
            url: action.url,
            mediaType: action.mediaType,
        };
    case HIDE_PARTICIPATION_NFT_PREVIEW_DIALOG:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    participationNftEnable,
    totalUnits,
    initialNftIndex,
    denomId,
    creatorValue,
    videoDropsName,
    videoDropsDescription,
    videoDropsTweetText,
    counterSwitch,
    royalitySwitch,
    royalityShare,
    transferabilitySwitch,
    extensibilitySwitch,
    nfswSwitch,
    videoDropsPreviewURL,
    videoDropsMediaURL,
    collection,
    nftSelectCollections,
    participationNftConfig,
    participantNFTAuthzAccountAddress,
    requiredPercentage,
    mintedUntilValue,
    participantNftPreviewDialog,
});
